
/* All Pages Common Style */

html{-webkit-font-smoothing:antialiased;-moz-font-smoothing:antialiased;font-smoothing:antialiased;}
body{margin:0;padding:0; font-family: 'Montserrat', sans-serif; background: #252525; font-size:16px; color: #1E1E1E; overflow-x: hidden; overflow-y: auto;}
ul, ol, p, form, input, textarea, select, pre, em, sub, sup, canvas, section, article, aside, img, a, li, iframe, table, nav, header, footer, body, menu, button{margin:0;padding:0;font-family: 'Montserrat', sans-serif;vertical-align:baseline;box-sizing:border-box;-webkit-box-sizing:border-box;list-style:none;text-decoration:none;outline:none;border:0;border-spacing:0;border-collapse:collapse;}
*, after, before{box-sizing:border-box;-webkit-box-sizing:border-box;}
h1, h2, h3, h4, h5, h6{margin:0;padding:0;font-weight:normal;}
h4{font-size:18px;}
body{ line-height: 1; min-height: 100vh; }
a {color: #000; text-decoration: none; transition: all 0.2s ease-in-out 0s; -webkit-transition: all 0.2s ease-in-out 0s; }
a:hover {text-decoration: none; color:#444; }
p{ line-height: 1.4; margin-bottom: 10px; }
b, strong{ font-weight: 600; }

u{text-decoration:underline;}
i, em{font-style:italic;}
img{max-width:100%;}
.clear {clear:both;}
input[type="button"], input[type="submit"], input[type="reset"],
input[type="text"]{appearance:none;-webkit-appearance:none; -webkit-appearance:none;}
input[type="button"], input[type="submit"], input[type="reset"]{cursor:pointer;font-size:16px;}
/*select::-ms-expand { display: none; }*/
.clearfix{ clear: both; }


.main_outer{ width: 100%; max-width: 375px; min-height: 100vh; background: #EAEAEA; margin: 0 auto; }
.container{ padding: 25px; max-width: 100% !important; }
h1.heading-title{ display: block; font-size: 22px; line-height: 28px; font-weight: 400; }

.btn{ padding: 0px 18px; border-radius: 15px; font-weight: 700; height: 50px; line-height: 50px; min-width: 200px; text-align: center; }
.btn:focus{ outline: none !important; box-shadow: none !important; }

.btn-primary{ border-color: #1E1E1E; background-color: #1E1E1E; color: #fff;}

.btn-primary:hover,
.btn-check:focus+.btn-primary, 
.btn-primary:focus,
.btn-primary.disabled, 
.btn-primary:disabled{border-color: #000;background-color: #000;color: #fff;}

.btn-white{border-color: #ffffff;background-color: #ffffff;color: #1E1E1E;}
.btn-white:hover{border-color: #C4C4C4;background-color: #C4C4C4;color: #1E1E1E;}

.btn-outline{border-color: #000000; background-color: transparent; color: #1E1E1E;}
.btn-outline:hover{border-color: #1E1E1E;background-color: #1E1E1E;color: #ffffff;}

.form-control::-webkit-input-placeholder { color: #969EAB; opacity: 1; }
.form-control::-moz-placeholder { color: #969EAB; opacity: 1; }
.form-control:-ms-input-placeholder { color: #969EAB; opacity: 1; }
.form-control:-moz-placeholder { color: #969EAB; opacity: 1; }

.form-label{ font-weight: 700; margin-bottom: 5px;}
.form-control{ display: block; width: 100%; height: 50px; background-color: #CCCCCC; border-radius: 15px; font-weight: 500; padding: 0 15px; border:0; font-size: 16px; }
.form-control:focus{ outline: none; background-color: #CCCCCC; box-shadow: 0 0 0 2px #888; }
.footer_space{ padding-bottom: 145px !important; }

.banner-with-header{ display: block; width: 100%; }
.banner-top{ display: block; width: 100%;height:auto; min-height: 150px; }
.banner-top img{ display: block; width: 100%; object-fit: cover;max-height: 188px; }
.page-name{ clear: both; margin: 0; background: #1E1E1E; color: #fff; font-weight: 700; font-size: 16px; padding: 10px 25px; min-height: 50px; display: flex; align-items: center; }
.sub-title{ display: block; font-size: 14px; color: #666666; line-height: 22px; font-weight: 400; }
.sub-title ul, .sub-title li{
  list-style: initial;
}

.footer{ position: fixed; z-index: 1000; bottom: 0; width: 375px; }
.download-app{ display: flex; align-items: center; justify-content: space-between; width: 100%; background:  #CCCCCC; box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.25); border-radius: 5px 5px 0px 0px; height: 75px; padding: 10px 25px; }
.download-app h3{ margin-bottom: 0; font-size: 14px; font-weight: 700; color: #333333; text-transform: capitalize; line-height: 1.4; padding-right: 10px;}
.btn-download{ display: inline-block; border: 1px solid #1E1E1E; border-radius: 10px; width: 100px; min-width: 100px; text-align: center; font-size: 14px; font-weight: 700; color: #333333; padding: 10px 0; }
.btn-download:hover{ background-color: #1E1E1E; color: #fff; }

.nav-bar{ display: flex; padding: 0; width: 100%; height: 60px; background: #1E1E1E; align-items: center; justify-content: space-between; }
.nav-bar div{ width: 33%; text-align: center; }
.nav-bar a{ width: 28px; height: 28px; display: inline-block; cursor: pointer; background: url(../images/nav-icons.svg) 0 1px no-repeat; background-size: 115px; }
.nav-bar a.home-btn{ background-position: -43px 1px; }
.nav-bar a.contact-btn{ background-position: right 1px; }
.nav-bar a.profile-btn.active{ background-position: left bottom; }
.nav-bar a.home-btn.active{ background-position: -43px bottom; }
.nav-bar a.contact-btn.active{ background-position: right bottom; }

.innerpage{ display: block; width: 100%; }
.n-heading{ font-weight: 700; font-size: 14px; }
.contact-info-box{ display: flex; width: 100%; align-items: center; font-weight: 700; color: #809DA7; font-size: 14px; word-break: break-word;}
.contact-info-box strong{ color: #1E1E1E; font-weight: 700; min-width: 100px;}
.contact-info-box a{ color: inherit; }

.logo-circle{ display: block; position: relative; z-index: 2; min-width: 74px; width: 74px; height: 74px; border-radius: 50%; overflow: hidden; background: #666666; margin:0 auto; }
.logo-circle img{ width: 100%; height: 100%; object-fit: cover; display: block; }

.page-heading{ display: block; font-size: 22px; font-weight: 400; }

.title-bar{ display: flex; align-items: center; width: 100%; position: fixed; top: 0; width: 375px; z-index: 1000; background: #1E1E1E; height: 94px; }
.title-bar .container{ padding-top: 10px; padding-bottom: 10px; }
.title-bar-box{ display: flex; width: 100%; align-items: center; justify-content: flex-start; }
.back-page{ min-width: 30px; height: 30px; background: url(../images/back.svg) center no-repeat; cursor: pointer; border-radius: 50px; margin-left: -10px; }
.back-page:hover{ background-color: rgba(255, 255, 255, 0.1); }
.title-bar-box h2{ flex-grow: 1; text-align: center; color: #fff; font-weight: 700; font-size: 16px; word-break: break-word; }

.title-bar-space{ padding-top: 94px; }
.section-title{ display: block; font-weight: 700; font-size: 16px; color: #000;text-transform: capitalize; }

.nav-links{ display: block; clear: both; width: 100%; border-top: 1px solid #AAAAAA; }
.nav-links ul li{ display: block; clear: both; border-bottom: 1px solid #AAAAAA;  }
.nav-links ul li a{ display: flex; align-items: center; padding: 10px 40px 10px 0; clear: both; font-weight: 700; font-size: 14px; color: #333333; min-height: 50px; background: url(../images/right-icon.svg) 98% center no-repeat; }
.nav-links ul li a:hover{ background-position: right center; }
.color-white{ color: #ffffff !important; }
.desc-min-height{
  min-height: 25vh;
}

.page-box{
  display: block;
  width: 100%;
}

/* All Pages Common END */

.error-page{ display: flex; width: 100%; height: 100vh; align-items: center; justify-content: flex-start; padding-bottom: 50px; }
.error-page h1.heading-title{ padding: 0 20px; }

app-get-started .welcome-page-bottom-blank{ height: 134px; width: 100%; display: block; background: #C4C4C4; position: relative; z-index: 2; }
/* app-sign-up + app-layout-footer .welcome-page-bottom-blank{ height: 134px; width: 100%; display: block; background: #C4C4C4; position: relative; z-index: 2; } */


/* Error Page START */

.error_flex{ display: flex; align-items: center; justify-content: space-between; flex-direction: column; min-height: 100vh; }

/* Error Page END */


.keycode{ display: block; }
.keycode strong{ font-size: 14px; font-weight: 700; }
.keycode div{ font-weight: 400; font-size: 22px; line-height: 28px; padding: 10px 0 12px 0; }
.keycode .access{color:#809DA7;}
.keycode .access-revoked{color:#EA6969;}
.location-box{ display: block; width: 100%; padding-left: 30px; background: url(../images/location.svg) left top no-repeat;  margin-top: 38px; }
.location-box span{ display: block; color: #809DA7; font-weight: 700; font-size: 14px; border-bottom: 1px dashed #000000; padding-bottom: 8px; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}

.location-box{ display: block; width: 100%; padding-left: 30px; background: url(../images/location.svg) left top no-repeat;  margin-top: 38px; }
.location-box span{ display: block; color: #809DA7; font-weight: 700; font-size: 14px; border-bottom: 1px dashed #000000; padding-bottom: 8px; }

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus{
  box-shadow: 0 0 0 2px rgb(220 53 69 / 25%);
}


.banner-top{ position: relative; }
.backbtn_banner{ position: absolute; top: 15%; left: 25px; width: 40px; height: 40px; border-radius: 50px; background:#1E1E1E url(../images/back.svg) center no-repeat; 
  /* transform: translate(0, -50%); -webkit-transform: translate(0, -50%); */
   z-index: 5; }
.supporttextarea{ height: 180px; padding-top: 10px; padding-bottom: 10px; }

.btn-cancel{ color:#EA6969;background-color: #CCCCCC;border:inherit}

.moving-box svg{
  width:180px !important;
  height: 180px !important;
}

.sliderowl .owl-carousel .owl-item img{ 
  /* height: 150px; */
   object-fit: cover;
   object-position: center;
  }
.sliderowl .owl-theme .owl-nav.disabled+.owl-dots{    margin-top: 10px;position: absolute;bottom: 0; left: 0; right: 0;}
.owl-theme .owl-nav [class*=owl-]{ background: rgba(255, 255, 255, 0.5);}
.owl-theme .owl-dots .owl-dot span{
  background: #fff !important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background: #1E1E1E !important;
}

.page-name span{ font-weight: 400; margin-left: auto; margin-right: 0;white-space: nowrap; }

/**Not Found Box **/

.v_flexbxx{ display: flex; align-items: center; justify-content: center; flex-direction: column; min-height: calc(100vh - 350px); }
.notfound_container { min-height: calc(100vh - 145px); }
.notfound_container .container { padding: 50px; }
.notfound_container .notfound_box { display: block; padding: 30px; background: #1E1E1E; border-radius: 10px; min-height: 300px; text-align: center; }
.notfound_container .notfound_box .notfound_icon { display: block; margin-bottom: 1.2rem;}
.notfound_container .notfound_box .notfound_icon img { width: 135px; max-height: none; }
.notfound_container .notfound_box h3 { display: block; color: #fff; font-size: 14px; font-weight: 700; }
.notfound_container .notfound_box p { color: #AAAAAA; font-size: 14px; }
.notfound_container .btn { background: #333333; }
.remove-footer-space{
  margin-bottom: -145px;
}
.action-link{
  color: #809DA7;
}
.sliderowl{ position: relative; z-index: 1; }
    .backbtn_banner{ visibility: visible; opacity: 1; display: inline-block; }
    .banner-top img{height: 188px;}
    .linkBox_content{
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }

.linkBox_content strong{ 
  font-weight: 700; display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.download-app .close_icon{
  position: absolute;
    right: 6px;
    top: 3px;
}
.download-app .close_icon img{
  width:18px;
}


.guest_verification img{ width: 125px;}
.guest_verification h2{ font-size:22px; color: #1E1E1E; font-weight: 700;}
.guest_verification h4{ font-size:14px; color: #1E1E1E; font-weight: 400; line-height: 28px;}

.help_icon{ min-width: 30px; height: 30px; background: url(../images/help_icon.svg) center no-repeat; cursor: pointer; border-radius: 50px; margin-left: -10px; }
.help_icon:hover{ background-color: rgba(255, 255, 255, 0.1); }
.title-bar-box h3{ flex-grow: 1; font-weight: 700; font-size: 16px; word-break: break-word; }


.ft_box_close{
  position: fixed;
  bottom: 165px;
  left: 0;
  right: 0;
}

.nextBtnFix{
  border-color: #1E1E1E;
  background-color: #1E1E1E;
  color: #fff;
  width: calc(100% - 50px);
  margin-left: 25px;
}


/*datepicker theme color*/

.theme-default .bs-datepicker-head {
  background-color: #1E1E1E;
}
.theme-default .btn-today-wrapper .btn-success, .theme-default .btn-clear-wrapper .btn-success {
  background-color: #1E1E1E;
  border-color: #1E1E1E;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:focus, .theme-default .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161;
}
.theme-default .btn-today-wrapper .btn-success:hover, .theme-default .btn-clear-wrapper .btn-success:hover {
  background-color: #6F6E6E;
  border-color: #6F6E6E;
}
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #1E1E1E;
}
.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #1E1E1E;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #1E1E1E;
}
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #1E1E1E;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.webcame_box img{
  display: block;
}

.btn-secondary{
  color: #333;
  background-color: #ccc;
  border-color: #ccc;
}

.goog-te-gadget-icon {
  display:none;
}


.goog-te-gadget-simple {
    background-color: #ecebf0 !important;
    border:0 !important;
    font-size: 10pt;
    font-weight:800;
    display: inline-block;
    padding:10px 10px !important;
    cursor: pointer;
    zoom: 1;
}

.goog-te-gadget-simple  span {
   color:#3e3065 !important;

}
.skiptranslate{
  visibility: 0;
}

.skiptranslate{ display: none;}
body.translator .skiptranslate{ display: block;}

.skiptranslate{  margin: 10px auto; 

  margin: 10px auto;
  border-radius: 6px;
  text-align: center;
}
.goog-te-gadget-simple{ background:transparent !important; border-radius: 6px; display: inline-block; text-align: left; border: solid 1px #1e1e1e !important;} 

body.translator .skiptranslate.goog-te-gadget{ display: block !important; }
#goog-gt-tt{ display: none !important;}

.translator .vertical-flex-box{ min-height: calc(100vh - 215px) !important;}
body.translator{ top: 0 !important;}

.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div{
  color: #1e1e1e !important;
}

.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div{ color:#1e1e1e !important;}
body .goog-te-gadget-simple span {
    color: #1e1e1e !important;
}

#google_translate_element .goog-te-gadget a{color:#1e1e1e}
#google_translate_element .goog-te-menu2-item div, 
#google_translate_element .goog-te-menu2-item:link div,
#google_translate_element .goog-te-menu2-item:visited div,
#google_translate_element .goog-te-menu2-item:active div{
  color:#1e1e1e
}

.red-text{
  color:#EA6969
}

img[src$="#custom_marker"]{
  border: 2px solid transparent !important;
  border-radius:50%;
}

font{ line-height: normal;}
body{ top:0 !important; }

body.verification .download-app{
  display: none !important;
}

.skip{
  color:#fff
}

.title-bar-space{ padding-top: 0; flex-grow: 1;    display: flex !important; flex-direction: column;  }
.title-bar,.footer{ position: sticky !important;  }
.main_outer{ display: flex !important; flex-direction: column; }
.footer_space{ padding-bottom: 0 !important; }
.innerpage{ height: auto; flex-grow: 1; display: flex !important; flex-direction: column; }
.footer{ top: auto; margin-top: auto; }
.container{ flex-grow: 1; }
.vflexcheck{ min-height: auto; }
/* .getRide{ position: relative !important; bottom: 0; } */
.verticalheight-scroll-box{ flex-grow: 1; overflow: auto; -webkit-overflow-scrolling:touch; }
.alignitems-flex,
.vertical-flex-box{ min-height: 50px; flex-grow: 1; }
.error_flex .container,
.welcome-section .container{ flex-grow: 0; }
.welcome-section.footer_space { padding-bottom: 0 !important; }

.fixedtabs{ position: fixed; top: auto; z-index: 50; background: #eaeaea; }
.fixedtabs_space{ padding-top: 83px; }
app-layout-footer{
  top: auto;
  margin-top: auto;    z-index: 1000;
  bottom: 0;    position: sticky !important;
} 

body.translator app-layout-footer{ display: none; }

#recommendations-map-view .mCustomScrollBox{ margin-right: 0;}